    body{
        color: white;
        /* background-image: url('billing-up.svg'); */
        background-color: hsl(0, 0%, 0%);
     }
     .hero::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 600px;
        background-image: url('billing-up.svg');
        background-size: cover;
        filter: blur(80px);
        opacity: .4;
        z-index: -1; /* Ensure the pseudo-element is behind other content */
    }


    .container2{
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 15px;
    }

.hero{
    margin-top: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(50px); */
  
}
.main-text{
    text-align: center;
    width: 1000px;
    font-size: 4.375rem;
    font-weight: 700;
    line-height: 1.3;
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
}
.subtitle-text{
    text-align: center;
    width: 800px;
    line-height: 1.5;
    margin-top: 25px;
    color:#97a4b6;
    padding: 5px 15px;
    font-size: 1.25rem;
    font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

.hero-button{
    margin-top: 25px;
    display: flex;
    gap:50px;
}

.hero-button .lets-talk{
    /* background-color: #64bbcd; */
    background-color: oklch(84.42% 0.19 202.24);
    color: black;
    /* color: #fff; */
    font-size: 20px;

    padding:12px 20px;
    /* border: 0; */
    border-radius: 10px;
    z-index: 10;
    min-height: 3.75rem;
    min-width: 10.25rem;
    /* background-color: var(--color--primery-500); */
    
    text-align: center;
    border: 1px #d1dfe4;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    font-size: 1.25rem;
    text-decoration: none;
    display: flex;
    position: relative;
    overflow: hidden;
}

.learn-more{
    z-index: 10;
    min-height: 3.75rem;
    min-width: 10.25rem;
    background-color: transparent;
    color: #fff;
    text-align: center;
    border: 1px solid #f4f4f4;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    padding-left: 1.875rem; 
    padding-right: 1.875rem;
    font-size: 1.25rem;
    text-decoration: none;
    display: flex;
    position: relative;
    overflow: hidden;
}

.hero-highlight-text{
    color: #64bbcd;
    padding-left: 10px;
}
.hero-fix-bottom {
    height: 300px;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000); */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.about-denvil{
    margin-top: 200px;
    height: 150vh;
    /* background-color: black; */
    width: 100%;
}

.about-denvil img{
    width: 100%;
    border-radius: 50px;
}

.last-project{
    font-size: 65px;
    margin: 80px 0px;
    font-weight: 800;
    font-family: "Manrope", sans-serif;
}

.liva-project{
    /* background-color: #fff; */
    margin: 200px 0;
    display: flex;
    gap:50px;
    justify-content: space-between;
}
.left{
    /* margin-top: 50px; */
    /* background-color: #8c8c8c; */
    width: 580px;
    height: 500px;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* background-color: green; */
    align-items: flex-start;
}
.right{
    /* width: 600px;
    height: 400px; */
    /* background-image: url('../images/image\ copy.png'); */
    /* background-color: #434343; */
    /* padding: 50px; */
    min-height: 500px;
    background-image: linear-gradient(#000000, #151414);
    backdrop-filter: blur(50px);
    border: 2px solid #4c4b4b;
    border-radius: 21px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
}
.right img{
    border-radius: 21px;  
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.liva-title{
    font-size: 50px;
    margin: 0;
    padding: 0;
    font-weight: 600;
    color: #f4f4f4;
    font-family: "Inter", sans-serif;
}
.liva-desc{
    margin-top: 60px;
    color: grey;
}
.tags{
    grid-column-gap: .75rem;
    grid-row-gap: 1rem;
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
    margin-top: 30px;
}

.tag-item{
    border: 1px solid white;
    background-color: transparent;
    color: rgb(182, 182, 182);
    border-radius: 6rem;
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
}
.view-more-btn{
    margin-top: 30px;
    /* display: flex; */
    /* gap:10px */

}
.view-more-btn button{
    display: flex;
    gap:15px;
    height: 40px;
    font-family: "Inter", sans-serif;

    padding: 10px 20px;
    font-size: 1.125rem;
    font-weight: 500;
    border:0;
    --buttonSize: 3.5rem;
    --buttonFontSize: 1.125rem;
    --buttonFocusWidth: 4px;
    --buttonPadding: 0 24px;
    --buttonTextColor: black;
    --buttonTextOpacity: 1;
    --buttonLoaderDelay: 0s;
    height: var(--buttonSize);
    padding: var(--buttonPadding);
    cursor: pointer;
    transition-property: opacity, color, background;
    transition-duration: var(--durationS);
    transition-timing-function: var(--bezierFastoutSlowin);
    display: inline-flex;
    align-items: center;
    color: var(--buttonTextColor);
    position: relative;
    isolation: isolate;
    background-color: oklch(84.42% 0.19 202.24);
    color:rgb(4, 4, 4); 
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}

.circle_bg3{
    margin-top: 180px;
    overflow: hidden;
    position: absolute;
     margin-left:380px;
     /* margin-top: 50px; */
    /* display: flex;
    justify-content: center; */
    /* right: -100px; */
    height:250px;
    width: 250px;
    filter: blur(180px);
    border-radius: 50%;
    z-index:-1;
    background-color: rgba(44, 212, 212, 0.685);
  }